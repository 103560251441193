import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import AlertModal from '../AlertModal';

function ContactForm() {
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Message, setMessage] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    function Sendmail(e) {
        e.preventDefault(); // Prevent form from refreshing the page

        const Data = {
            to_name: Name,
            to_email: Email,
            message: Message
        };

        const Service_id = "service_hxu03qa";
        const Template_id = "template_i9wsdd5";
        const User_id = "F7mGvyYtAk2zFgJhY";

        emailjs.send(Service_id, Template_id, Data, User_id)
            .then(
                function (response) {
                    setAlertMessage("Message Sent Successfully!");
                    setShowAlert(true);
                    resetForm(); // Reset form fields after successful submission
                },
                function (error) {
                    setAlertMessage("Error sending message. Please try again.");
                    setShowAlert(true);
                }
            );
    }

    function resetForm() {
        setName("");    // Clear the Name field
        setEmail("");   // Clear the Email field
        setMessage(""); // Clear the Message field
    }

    function closeAlert() {
        setShowAlert(false);
    }

    return (
        <>
            <form className="wpcf7-form init demo" onSubmit={Sendmail}>
                <div className="cntrt-contact-form">
                    <div className="cntrt-grid cntrt-layout--columns cntrt-col-num--2">
                        <div className="cntrt-grid-inner">
                            <div className="cntrt-grid-item">
                                <span className="wpcf7-form-control-wrap">
                                    <input
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text"
                                        placeholder="Name"
                                        type="text"
                                        name="your-name"
                                        required
                                        value={Name} // Bind input value to state
                                        onChange={(e) => { setName(e.target.value) }}
                                    />
                                </span>
                            </div>
                            <div className="cntrt-grid-item">
                                <span className="wpcf7-form-control-wrap" data-name="your-email">
                                    <input
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email"
                                        placeholder="Email"
                                        type="email"
                                        required
                                        name="your-email"
                                        value={Email} // Bind input value to state
                                        onChange={(e) => { setEmail(e.target.value) }}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <span className="wpcf7-form-control-wrap" data-name="your-message">
                        <textarea
                            cols={40}
                            rows={5}
                            required
                            className="wpcf7-form-control wpcf7-textarea"
                            placeholder="Message"
                            name="your-message"
                            value={Message} // Bind textarea value to state
                            onChange={(e) => { setMessage(e.target.value) }}
                        />
                    </span>
                    <button
                        className="wpcf7-form-control wpcf7-submit cntrt-button cntrt-size--normal  cntrt-m"
                        type="submit"
                    >
                        <span className="cntrt-m-text">Send</span>
                    </button>
                </div>
            </form>

            {showAlert && <AlertModal message={alertMessage} onClose={closeAlert} />}
        </>
    );
}

export default ContactForm;
