import React from 'react';
import '../../src/assets/css/index.css';
import '../../src/assets/css/animate.min.css';
import '../../src/assets/css/custom-frontend-lite.min.css';
import '../../src/assets/css/grid.min.css';
import '../../src/assets/css/level-core.min.css';
import '../../src/assets/css/main.min.css';
// import InteractivePage from '../assets/js/InteractivePage';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
    <footer id="cntrt-page-footer" role="contentinfo">
      <div id="cntrt-page-footer-top-area">
        <div
          id="cntrt-page-footer-top-area-inner"
          className="cntrt-content-full-width cntrt-layout-columns--4-predefined"
        >
          <div className="cntrt-grid cntrt-layout--columns cntrt-responsive--custom cntrt-col-num--4">
            <div className="cntrt-grid-inner clear">
              {/* Column 1 */}
              <div className="cntrt-grid-item">
                <div
                  id="level_core_single_image-2"
                  className="widget widget_level_core_single_image"
                  data-area="cntrt-footer-top-area-column-1"
                >
                  <div className="cntrt-shortcode cntrt-m cntrt-single-image cntrt-layout--default cntrt--retina">
                    <div className="cntrt-m-image">
                      <Link to="/">
                        <img itemProp="image" className='foo-logo' src="https://level-contractor.thepreview.pro/images/logo.png" alt="Level Logo" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Column 2 */}
              <div className="cntrt-grid-item">
                <div
                  id="block-15"
                  className="widget widget_block"
                  data-area="cntrt-footer-top-area-column-2"
                >
                  <div className="footer-mb wp-block-columns is-layout-flex wp-container-core-columns-is-layout-2 wp-block-columns-is-layout-flex">
                    <div className="wp-block-column is-layout-flow wp-block-column-is-layout-flow">
                      <div className="widget widget_level_core_title_widget">
                        <h6 className="cntrt-widget-title">Social</h6>
                      </div>
                      <p style={{ marginTop: '-5px', fontSize: '15px' }}>
                        <a
                          className="cntrt--underline-on-hover"
                          href="###"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Instagram
                        </a>
                      </p>
                      <p style={{ marginTop: '-5px', fontSize: '15px' }}>
                        <a
                          className="cntrt--underline-on-hover"
                          href="###"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Facebook
                        </a>
                      </p>
                      <p style={{ marginTop: '-5px', fontSize: '15px' }}>
                        <a
                          className="cntrt--underline-on-hover"
                          href="###"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          LinkedIn
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Column 3 */}
              <div className="cntrt-grid-item">
                <div
                  id="level_core_title_widget-5"
                  className="widget widget_level_core_title_widget"
                  data-area="cntrt-footer-top-area-column-3"
                >
                  <h6 className="cntrt-widget-title">Info</h6>
                </div>
                <div
                  id="block-41"
                  className="widget widget_block"
                  data-area="cntrt-footer-top-area-column-3"
                >
                  <p style={{ marginTop: '-5px', fontSize: '15px' }}>
                  <Link className="cntrt--underline-on-hover"
                      to="/services" >Services</Link>
                  </p>
                  <p style={{ marginTop: '-5px', fontSize: '15px' }}>
                  <Link className="cntrt--underline-on-hover"
                      to="/blog" >Blog</Link>
                  </p>
                  <p style={{ marginTop: '-5px', fontSize: '15px' }}>
                    <Link className="cntrt--underline-on-hover"
                      to="/contact" >Contact</Link>
                  </p>
                </div>
              </div>

              {/* Column 4 */}
              <div className="cntrt-grid-item">
                <div
                  id="level_core_title_widget-6"
                  className="widget widget_level_core_title_widget"
                  data-area="cntrt-footer-top-area-column-4">
                  <h6 className="cntrt-widget-title">Contact</h6>
                </div>
                <div
                  id="block-42"
                  className="widget widget_block"
                  data-area="cntrt-footer-top-area-column-4">
                  <p style={{ marginTop: '-5px', fontSize: '15px' }}>
                    <a
                      className="cntrt--underline-on-hover"
                      href="https://www.google.com/maps/search/2125+Chamomile+ct%C2%A0+San+Ramon,+Ca+94582?entry=gmail&source=g"
                      rel="noreferrer noopener">2125 Chamomile ct San Ramon, Ca 94582</a>
                  </p>
                  <p style={{ marginTop: '-5px', fontSize: '15px' }}>
                    <a
                      className="cntrt--underline-on-hover"
                      href="tel:8182972920"                      
                    >+1 818-297-2920
                    </a>
                  </p>
                  <p style={{ marginTop: '-5px', fontSize: '15px' }}>
                    <a
                      className="cntrt--underline-on-hover"
                      href="mailto:levelcontractor1@gmail.com"
                      
                    >
                      levelcontractor1@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="cntrt-page-footer-bottom-area">
        <div
          id="cntrt-page-footer-bottom-area-inner"
          className="cntrt-content-full-width"
        >
          <div className="cntrt-grid cntrt-layout--columns cntrt-responsive--custom cntrt-col-num--2 cntrt-col-num--680--1 cntrt-col-num--480--1">
            <div className="cntrt-grid-inner clear">
              <div className="cntrt-grid-item">
                <div
                  id="block-17"
                  className="widget widget_block"
                  data-area="cntrt-footer-bottom-area-column-1"
                >
                  <h6 style={{ color: '#adadad' }} className="cntrt--underline-on-hover">
                  2024 All Rights Reserved By Level Contractor | Designed by{' '}
                    <a
                      href="https://zonewebsites.us" target='_blank'
                      rel="noreferrer noopener"
                    >
                        Zone Websites
                    </a>
                  </h6>
                </div>
              </div>
              <div className="cntrt-grid-item"></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
   
    </>
    
  );
};

export default Footer;
