import React from 'react'
import ContactForm from './ContactForm'

const HomeContact = () => {
  return (
    <div>
        
                    <div className="level-container level-column-gap-default">
                      <div
                        className="level-column level-col-50 level-top-column level-element level-element-3e77b4a"
                        data-id="3e77b4a"
                        data-element_type="column"
                      >
                        <div className="level-widget-wrap level-element-populated">
                          <div
                            className="level-element level-element-69bb51b level-widget level-widget-level_core_icon_with_text"
                            data-id="69bb51b"
                            data-element_type="widget"
                            data-widget_type="level_core_icon_with_text.default"
                          >
                            <div className="level-widget-container">
                              <div className="cntrt-shortcode cntrt-m  cntrt-icon-with-text cntrt-layout--before-content cntrt--icon-pack ">
                                <div className="cntrt-m-icon-wrapper">
                                  <span
                                    className="cntrt-shortcode cntrt-m  cntrt-icon-holder cntrt-size--default cntrt-layout--circle"
                                    style={{
                                      width: 4,
                                      height: 4,
                                      lineHeight: 4,
                                    }}
                                  ></span>
                                </div>
                                <div className="cntrt-m-content">
                                  <h6
                                    className="cntrt-m-title"
                                    style={{ marginTop: "-5px" }}
                                  >
                                    <span className="cntrt-m-title-text">
                                    LET’S DISCUSS YOUR PROJECT
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="level-element level-element-50ebd58 level-widget level-widget-level_core_section_title"
                            data-id="50ebd58"
                            data-element_type="widget"
                            data-widget_type="level_core_section_title.default"
                          >
                            <div className="level-widget-container">
                              <div className="cntrt-shortcode cntrt-m  cntrt-section-title cntrt-alignment--left ">
                                <h2 className="mb-head cntrt-m-title ">
                                We’ve helped turn dreams into reality for homeowners in San Ramon, California. Contact us today!
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="level-column level-col-50 level-top-column level-element level-element-c7866c6"
                        data-id="c7866c6"
                        data-element_type="column"
                      >
                        <div className="level-widget-wrap level-element-populated">
                          <div
                            className="level-element level-element-35fe2ac level-widget level-widget-wp-widget-level_core_contact_form_7"
                            data-widget_type="wp-widget-level_core_contact_form_7.default"
                          >
                            <div className="level-widget-container">
                              <div className="cntrt-contact-form-7">
                                <div className="wpcf7 no-js" id="wpcf7-f1387-p1303-o1" lang="en-US" dir="ltr">
                                 
                                 <ContactForm/>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 
    </div>
  )
}

export default HomeContact