// import React from 'react';
import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import HomeRemodeling from './assets/images/Services/Home-Remodeling.webp';
import KitchenRemodeling from './assets/images/Services/Kitchen-Remodeling.webp';
import BathroomRemodeling from './assets/images/Services/Bathroom-Remodeing.webp';
import ADU from './assets/images/Services/ADU.webp';
import ConcreteWork from "./assets/images/Services/Concrete-work.webp"
import Driveways from './assets/images/Services/Driveways.webp'
import HomeAdditions from "./assets/images/Services/Home-additions.webp"
import GarageConversions from "./assets/images/Services/Garage-Conversions.webp"
import Roofing from "./assets/images/Services/Roofing.webp"
import Landscaping from "./assets/images/Services/Landscaping.webp"
import WindowInstallation from "./assets/images/Services/Window-Installation.webp"
import DoorInstallation from "./assets/images/Services/Door-Installation.webp"
import ExteriorPainting from "./assets/images/Services/Exterior-Painting.webp"
import InteriorPainting from "./assets/images/Services/Interior-Painting.webp"

const services = [
  { id: 'home-remodeling', name: 'Home Remodeling', image: HomeRemodeling, description: 'If you are looking to renovate your house, you have come to the right place. At Level Contractor, we can help you transform your home and maximize its value. Our top-notch home remodeling services can enhance the functionality and appeal of your home. We can transform your home into what you’ve always wanted it to be. Our team of home remodeling professionals is committed to helping you bring your vision for your home to life. Get in touch with us today to discuss your project.' },
  { id: 'kitchen-remodeling', name: 'Kitchen Remodeling', image: KitchenRemodeling, description: 'The kitchen is the heart of your home. It’s where family and friends hang out. At Level Contractor, we understand the important role it plays in a family’s quality time. We offer comprehensive kitchen remodeling services to ensure that you get the kitchen of your dreams. Our commitment to excellence will make your kitchen look spectacular and boost its functionality. We take great pride in being a leading kitchen remodeling contractor in San Ramon and make sure we leave behind highly satisfied and happy customers.' },
  { id: 'bathroom-remodeling', name: 'Bathroom Remodeling', image: BathroomRemodeling, description: 'Level Contractor is the one-stop destination for all your bathroom remodeling needs. We can transform your bathroom to add more appeal to your home. From design to completion, we have you covered. Our team of dedicated professionals will make sure you get the impeccable bathroom you’ve been dreaming of having. Whether you’re considering changing the old style to a new look, improving aesthetics, or increasing functionality, you can count on us. We blend your vision with excellent craftsmanship to create the space you deserve.' },
  { id: 'adu', name: 'ADU', image: ADU, description: 'ADU or Accessory Dwelling Unit can be added to existing homes for a wide range of purposes. It enhances the value and livability of your properties. Whether you’re looking to have more space for family and guests or want to generate rental income, an ADU is an excellent way to leverage available space on your property. When building an Accessory Dwelling Unit (ADU), careful planning and efficient design are crucial to ensuring comfort and maintaining functionality. That’s where Level Contractor comes in. Our certified experts have the knowledge and experience to help build the ADU that’s just right for you.' },
  { id: 'home-additions', name: 'Home Additions', image: HomeAdditions, description: 'Enhance your home with a tailored home additions solution. At Level Contractor, we are ready to plan your new addition effectively. Our experience and expertise allow us to provide you with exceptional solutions, improving space functionality and enhancing its overall value. Our team is dedicated to providing high-quality services that meet your specific needs and preferences. Contact us today to receive quality craftsmanship and innovative design and discover a seamless and rewarding experience for your home addition.' },
  { id: 'garage-conversions', name: 'Garage Conversions', image: GarageConversions, description: 'At Level Contractor, we are experts in providing comprehensive garage conversion solutions. That is tailored to transform your unused space into functional living areas. Whether you require an extra bedroom, a home office, or a cozy lounge, our expert team helps you to deliver that. Converting your garage into a proper functional extension of your home boosts the value and versatility without striking the major construction. Contact us today to schedule an appointment and enjoy customized solutions for your unique needs.' },
  { id: 'roofing', name: 'Roofing', image: Roofing, description: 'If you’re wondering how to find a reliable roofing contractor, then you might be on the right path to achieving the answer. At Level Contractor, we are an extensive range roofing service. We provide our services to homeowners as well as commercial clients. Our long experience and expertise allow us to go beyond the problem and need to plan and deliver effective and secure roofing solutions. We are committed to providing top-tier services to safeguard all your roofing needs. Get in touch with us today to schedule an appointment to discuss your project. ' },
  { id: 'landscaping', name: 'Landscaping', image: Landscaping, description: 'At Level Contractor, we are proud to offer tailored landscape solutions designed to meet your gardening goal. We provide expert products & services to serve residential and commercial projects. We strive to exceed our client’s expectations and create unique landscapes that elevate the property’s design and value. Our team professional works through exceeding industry standards and guarantees you a finished landscape with the highest quality and functionality. Contact us today to book an appointment to discuss your project.' },
  { id: 'window-installation', name: 'Window Installation', image: WindowInstallation, description: 'We are the perfect pattern for you if you’re looking for expert window installation solutions. With various options available, we guide you through every step of replacing your existing windows. Our skilled team offers personalized service tailored to your needs. We strive to provide an excellent way to improve the build design, value, and energy efficiency of your property. Contact us today to guarantee your safest installation that exceeds industry standards at an affordable price. ' },
  { id: 'door-installation', name: 'Door Installation', image: DoorInstallation, description: 'If you are looking for a solution for door installation in your house, then we’ll be the right choice. We provide exceptional door installation services at Level Constructor, ensuring you will fit your space. We understand the purpose and aim to deliver the installation to keep your home safe. Whether it’s your front door, back door, or patio door, we provide a comprehensive range of solutions to cover all essentials. Our team works dedicated to each installation and strives to equip you with the best door product, style, and design to elevate your home. ' },
  { id: 'driveways', name: 'Driveways', image: Driveways, description: 'At Level Constructor, we offer top-quality driveway services for homeowners and businesses throughout the area. With our expertise, we strive to create concrete driveways with absolute precision and safety. Presenting a stable place for your vehicles without compromising your house’s exterior appeal. With our skills and knowledge, we offer prominent solutions to provide an effective installation that satisfies all your requirements. Whether you’re looking for solutions to a new driveway or need repairs for your existing one, we offer expert solutions to all the skills necessary to get the job done right.' },
  { id: 'concrete-work', name: 'Concrete Work', image: ConcreteWork, description: 'Painting seems like a simple task; however, it can be a lot more complex and labor-intensive than you anticipate. Therefore, at Level Contractor, we provide tailored exterior painting solutions. Our commitment is to deliver excellence in exterior painting. We assure you of a smooth, seamless experience with exceptional results at every home. We take great pride in being a leading exterior painting contractor in San Ramon. Get in touch with us today to elevate your exterior appeal with a high-quality expert paint job. ' },
  { id: 'exterior-painting', name: 'Exterior Painting', image: ExteriorPainting, description: 'Painting seems like a simple task; however, it can be a lot more complex and labor-intensive than you anticipate. Therefore, at Level Contractor, we provide tailored exterior painting solutions. Our commitment is to deliver excellence in exterior painting. We assure you of a smooth, seamless experience with exceptional results at every home. We take great pride in being a leading exterior painting contractor in San Ramon. Get in touch with us today to elevate your exterior appeal with a high-quality expert paint job. ' },
  { id: 'interior-painting', name: 'Interior Painting', image: InteriorPainting, description: 'Level Contractor is a leading company that offers comprehensive concrete work solutions to residential and commercial owners. Our long industrial experience and knowledge help us to deliver quality design and development with efficient project management. We’ve integrated new technologies and tailored solutions to bring innovation through our transformational solutions. We understand the importance of progress and building opportunities; thus, we aim to create structures that have impacts reaching far beyond the walls. Get in touch with us now to learn more about our services. ' },
];

const Services = () => {
  const { id } = useParams();
  const navigate = useNavigate();

   // Find the selected service or default to the first service
   const selectedService = services.find(service => service.id === id) || services[0];

    
     useEffect(() => {
      // Redirect to the first service if no ID is provided in the URL
      if (!id) {
        navigate(`/services/${services[0].id}`, { replace: true });
      }
  
      // Scroll to 200px when the component mounts or the ID changes
      window.scrollTo({
        top: 170,
        behavior: 'smooth' // Smooth scrolling
      });
    }, [id, navigate]);

  if (!selectedService) {
      return <div>Service not found</div>;
  }


  // Scroll function
  // const scrollToPosition = () => {
  //   window.scrollTo({
  //     top: 170,
  //     behavior: 'smooth'
  //   });
  // };



    return (


<div id="cntrt-page-outer">
      <div id="cntrt-page-inner" className="cntrt-content-full-width">
        <main id="cntrt-page-content" className="cntrt-grid cntrt-layout--template" role="main">
          <div className="cntrt-grid-inner clear">
            <div className="cntrt-grid-item cntrt-page-content-section cntrt-col--12">
              <div className="level level-3070">
                {/* First Section */}
              <section
                className="ser-first level-section level-top-section level-element level-section-full_width level-section-height-default"
                style={{ backgroundColor: '#a9b1ab' }}>
                <div className="level-container level-column-gap-default">
                  <div className="level-column level-col-100">
                    <div className="level-widget-wrap ">
                      <h1>Services</h1>
                    </div>
                  </div>
                </div>
              </section>

              <section className="ser-second level-section level-top-section level-section-full_width level-section-height-default"
                style={{ backgroundColor: '#a9b1ab' }}>
              <div className="service-detail-page">
                <div className="sidebar">
                <ul>
                    {services.map(service => (
                        <li key={service.id} className='category-buttonsgl'>
                            <Link to={`/services/${service.id}`} className={id === service.id ? 'active' : ''}>
                                {service.name}
                            </Link>
                        </li>
                    ))}
                </ul>
                </div>
                <div className="service-content">
                    <img src={selectedService.image} alt={selectedService.name} className="ser-banner-image" />
                    <h2>{selectedService.name}</h2>
                    <p>{selectedService.description}</p>
                    <div className='category-buttons ser-cont'><Link to="/contact"><button >Contact Us</button></Link></div>
                    
                </div>
            </div>
            </section>
		
      	      </div>
	    </div>
	  </div>
	</main>
      </div>
</div>

           
    );
};

export default Services;
