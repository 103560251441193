import React from 'react';
import './AlertModal.css';

function AlertModal({ message, onClose }) {
    return (
        <div className="alert-overlay">
            <div className="alert-modal">
                <div className="alert-content">
                    <h2 className="alert-title">Notification</h2>
                    <p className="alert-message">{message}</p>
                    <button className="alert-button" onClick={onClose}>OK</button>
                </div>
            </div>
        </div>
    );
}

export default AlertModal;
