import React from 'react'
import { Link } from 'react-router-dom'

const HomeWork = () => {
  return (
    <div>
       
                    <div className="level-container level-column-gap-default">
                      <div
                        className="level-column level-col-100 level-top-column level-element level-element-50d6c51"
                        data-id="50d6c51"
                        data-element_type="column"
                      >
                        <div className="level-widget-wrap level-element-populated work-sec-pd">
                          
                          
                          <div
                            className="level-element level-element-2d50417 level-widget level-widget-level_core_portfolio_list"
                            data-id="2d50417"
                            data-element_type="widget"
                            data-widget_type="level_core_portfolio_list.default">
                              <div className="heading-our-work"><h3>Our Works</h3></div>
                            <div className="level-widget-container">
                              <div
                                className="cntrt-shortcode cntrt-m  cntrt-portfolio-list cntrt-item-layout--info-below cntrt--zigzag cntrt-grid cntrt-layout--columns  cntrt-gutter--huge cntrt-col-num--4 cntrt-item-layout--info-below cntrt--no-bottom-space cntrt-pagination--off cntrt-responsive--custom cntrt-col-num--1440--4 cntrt-col-num--1366--4 cntrt-col-num--1024--4 cntrt-col-num--768--2 cntrt-col-num--680--1 cntrt-col-num--480--1 cntrt-hover-animation--move-text"
                                data-options='{"plugin":"level_core","module":"post-types\/portfolio\/shortcodes","shortcode":"portfolio-list","post_type":"portfolio-item","next_page":"2","max_pages_num":2,"behavior":"columns","images_proportion":"full","columns":"4","columns_responsive":"custom","columns_1440":"4","columns_1366":"4","columns_1024":"4","columns_768":"2","columns_680":"1","columns_480":"1","space":"huge","posts_per_page":"4","orderby":"date","order":"DESC","additional_params":"tax","tax":"portfolio-category","tax_slug":"details","layout":"info-below","hover_animation_info-below":"move-text","hover_animation_info-follow":"follow","hover_animation_info-on-hover":"fade-in","title_tag":"h5","enable_category":"yes","zigzag":"yes","custom_margin":"no","enable_appear":"no","pagination_type":"no-pagination","object_class_name":"HiroshiCore_Portfolio_List_Shortcode","taxonomy_filter":"portfolio-category","additional_query_args":{"tax_query":[{"taxonomy":"portfolio-category","field":"slug","terms":"details"}]},"space_value":37}'
                              >
                                <div className="cntrt-grid-inner clear">
                                  <article className="cntrt-e cntrt-grid-item cntrt-item--full  post-5005 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-details portfolio-tag-small">
                                    <div className="cntrt-e-inner">
                                      <div className="cntrt-e-image">
                                        <div className="cntrt-e-media-image">
                                          <Link to="/work"
                                            target="_self"
                                          >
                                            <img
                                              loading="lazy"
                                              decoding="async"
                                              width={1100}
                                              height={1439}
                                              src="images/Home/Window-Installation.webp"
                                              className="attachment-full size-full wp-post-image"
                                              alt="d"
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="cntrt-e-content">
                                        <div className="cntrt-e-text">
                                          <h5
                                            itemProp="name"
                                            className="cntrt-e-title entry-title"
                                          >
                                            <Link to="/work"
                                              className="cntrt-e-title-link"
                                              target="_self"
                                            >
                                              Window Installation{" "}
                                            </Link>
                                          </h5>
                                        </div>
                                        {/* <div className="cntrt-e-top-holder">
                                          <div className="cntrt-e-info">
                                            <a href="###" rel="tag">
                                              Details
                                            </a>
                                            <div className="cntrt-info-separator-end"></div>
                                          </div>
                                        </div> */}
                                      </div>
                                    </div>
                                  </article>
                                  <article className="cntrt-e cntrt-grid-item cntrt-item--full  post-5006 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-details portfolio-tag-small">
                                    <div className="cntrt-e-inner">
                                      <div className="cntrt-e-image">
                                        <div className="cntrt-e-media-image">
                                          <Link to="/work"
                                            target="_self"
                                          >
                                            <img
                                              loading="lazy"
                                              decoding="async"
                                              width={1100}
                                              height={740}
                                              src="images/Home/Door-Installation.webp"
                                              className="attachment-full size-full wp-post-image"
                                              alt="d"
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="cntrt-e-content">
                                        <div className="cntrt-e-text">
                                          <h5
                                            itemProp="name"
                                            className="cntrt-e-title entry-title"
                                          >
                                            <Link to="/work"
                                              className="cntrt-e-title-link"
                                              target="_self"
                                            >
                                              Door Installation{" "}
                                            </Link>
                                          </h5>
                                        </div>
                                        {/* <div className="cntrt-e-top-holder">
                                          <div className="cntrt-e-info">
                                            <a href="###" rel="tag">
                                              Details
                                            </a>
                                            <div className="cntrt-info-separator-end"></div>
                                          </div>
                                        </div> */}
                                      </div>
                                    </div>
                                  </article>
                                  <article className="cntrt-e cntrt-grid-item cntrt-item--full  post-5007 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-details portfolio-tag-small">
                                    <div className="cntrt-e-inner">
                                      <div className="cntrt-e-image">
                                        <div className="cntrt-e-media-image">
                                          <Link to="/work"
                                            target="_self"
                                          >
                                            <img
                                              loading="lazy"
                                              decoding="async"
                                              width={1100}
                                              height={1439}
                                              src="images/Home/Driveways.webp"
                                              className="attachment-full size-full wp-post-image"
                                              alt="d"
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="cntrt-e-content">
                                        <div className="cntrt-e-text">
                                          <h5
                                            itemProp="name"
                                            className="cntrt-e-title entry-title"
                                          >
                                            <Link to="/work"
                                              className="cntrt-e-title-link"
                                              target="_self"
                                            >
                                              Driveways{" "}
                                            </Link>
                                          </h5>
                                        </div>
                                        {/* <div className="cntrt-e-top-holder">
                                          <div className="cntrt-e-info">
                                            <a href="###" rel="tag">
                                              Details
                                            </a>
                                            <div className="cntrt-info-separator-end"></div>
                                          </div>
                                        </div> */}
                                      </div>
                                    </div>
                                  </article>
                                  <article className="cntrt-e cntrt-grid-item cntrt-item--full  post-5008 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-details portfolio-tag-small">
                                    <div className="cntrt-e-inner">
                                      <div className="cntrt-e-image">
                                        <div className="cntrt-e-media-image">
                                          <Link to="/work"
                                            target="_self"
                                          >
                                            <img
                                              loading="lazy"
                                              decoding="async"
                                              width={1100}
                                              height={653}
                                              src="images/Home/Exterior-and-Interior-Painting.webp"
                                              className="attachment-full size-full wp-post-image"
                                              alt="d"
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="cntrt-e-content">
                                        <div className="cntrt-e-text">
                                          <h5
                                            itemProp="name"
                                            className="cntrt-e-title entry-title"
                                          >
                                            <Link to="/work"
                                              className="cntrt-e-title-link"
                                              target="_self"
                                            >
                                              Exterior and Interior Painting{" "}
                                            </Link>
                                          </h5>
                                        </div>
                                        {/* <div className="cntrt-e-top-holder">
                                          <div className="cntrt-e-info">
                                            <a href="###" rel="tag">
                                              Details
                                            </a>
                                            <div className="cntrt-info-separator-end"></div>
                                          </div>
                                        </div> */}
                                      </div>
                                    </div>
                                  </article>
                                  
                                </div>
                                <div className='category-buttons work-btn'><Link to="/work"><button >See More Work</button></Link></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 
    </div>
  )
}

export default HomeWork