import React, { useState } from 'react';
import '../../src/assets/css/index.css';
import '../../src/assets/css/animate.min.css';
import '../../src/assets/css/custom-frontend-lite.min.css';
import '../../src/assets/css/grid.min.css';
import '../../src/assets/css/level-core.min.css';
import '../../src/assets/css/main.min.css';
import FullscreenArea from './FullSreenArea/FullscreenArea';
import { Link } from 'react-router-dom';

const Mheader = () => {

  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);

  const openFullscreen = () => {
    setIsFullscreenOpen(true);
  };

  const closeFullscreen = () => {
    setIsFullscreenOpen(false);
  };


    return (
        <>
      <header id="cntrt-page-mobile-header" role="banner">
        <div id="cntrt-page-mobile-header-inner" className="">
          <Link to="/" className="cntrt-mobile-header-logo-link cntrt-height--set cntrt-source--image" >
            <img
              src="https://level-contractor.thepreview.pro/images/logo.png"
              className="cntrt-header-logo-image cntrt--main"
              alt="logo main"
              itemProp="image"
              
            />
          </Link>
          


<div onClick={openFullscreen} className="cntrt-opener-icon cntrt-m cntrt-source--predefined cntrt-fullscreen-menu-opener menu-icon">
          <span className="cntrt-m-icon cntrt--open">
            <svg className="cntrt-svg--menu" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 84 84">
              <path d="M42.5.5a42 42 0 1 1-42 42 42 42 0 0 1 42-42Z" fill="none" stroke="currentColor"></path>
            </svg>
          </span>
          <span className="cntrt-m-icon cntrt--close">
            <svg className="cntrt-svg--menu-close" xmlns="http://www.w3.org/2000/svg" width="50" height="1" viewBox="0 0 50 1">
              <path d="M0 0h50v1H0z"></path>
            </svg>
          </span>
        </div>
      <FullscreenArea isOpen={isFullscreenOpen} onClose={closeFullscreen} />

      {isFullscreenOpen && <FullscreenArea />}
        </div>
      </header>
     
      </>
    );
  };
  
  export default Mheader;