import React from 'react'
import Home from './Home'
import Blog from './component/Blog/Blogs'
import Contact from './Contact'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Work from './Work'
import { Error } from './Error'
import FstBlogDetailPage from './component/Blog/FstBlogDetailPage';
import SecBlogDetails from './component/Blog/SecBlogDetails'
import TrdBlogDetails from './component/Blog/TrdBlogDetails'
import Services from './Services';
import ScrollToTop from './component/ScrollToTop';
// import Layout from './component/Layout';


const App = () => {
  return (
   <BrowserRouter>
   <ScrollToTop/>
      <Header />     
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path='/services/:id' element={<Services />} />
            <Route path="/work" element={<Work />} />
            <Route path="/blog" element={<Blog />} />
            <Route path='/6-common-bathroom-remodel-mistakes-and-how-to-avoid-them' element={<FstBlogDetailPage />} />
            <Route path='/top-trends-in-home-remodeling-transforming-your-space-with-style' element={<SecBlogDetails />} />
            <Route path='/how-to-estimate-new-home-construction-cost-a-complete-guide' element={<TrdBlogDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Error />} />
        </Routes>       
      <Footer />
   </BrowserRouter>
  )
}

export default App