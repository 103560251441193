import React, { useState } from 'react';
import '../../src/assets/css/index.css';
import '../../src/assets/css/animate.min.css';
import '../../src/assets/css/custom-frontend-lite.min.css';
import '../../src/assets/css/grid.min.css';
import '../../src/assets/css/level-core.min.css';
import '../../src/assets/css/main.min.css';
import Mheader from './Mheader';
import FullscreenArea from './FullSreenArea/FullscreenArea';
import { Link } from 'react-router-dom';



const Header = () => {
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);

  const openFullscreen = () => {
    setIsFullscreenOpen(true);
  };

  const closeFullscreen = () => {
    setIsFullscreenOpen(false);
  };

  return (
    <>
    <header id="cntrt-page-header" role="banner">
      <div id="cntrt-page-header-inner" className="">
        <Link
          itemProp="url"
          className="cntrt-header-logo-link cntrt-height--set cntrt-source--image"
          to="/">
          <img src="https://level-contractor.thepreview.pro/images/logo.png"
            className="cntrt-header-logo-image cntrt--main"
            alt="logo main"
            itemProp="image"/>
        </Link>
        <div className="cntrt-widget-holder cntrt--one">
          <div className="widget widget_block" data-area="home-6">
            <div className="wp-block-columns is-layout-flex wp-container-core-columns-is-layout-1 wp-block-columns-is-layout-flex">
              <div
                className="wp-block-column is-layout-flow wp-block-column-is-layout-flow"
                style={{ flexBasis: "58%" }}>
               <p>“Building is not just about shelter. It’s about realizing dreams, making statements, creating spaces where life happens.” </p>
              </div>
              
            </div>
          </div>
        </div>
        

      
      <div onClick={openFullscreen} className="cntrt-opener-icon cntrt-m cntrt-source--predefined cntrt-fullscreen-menu-opener menu-icon">
          <span className="cntrt-m-icon cntrt--open">
            <svg className="cntrt-svg--menu" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 84 84">
              <path d="M42.5.5a42 42 0 1 1-42 42 42 42 0 0 1 42-42Z" fill="none" stroke="currentColor"></path>
            </svg>
          </span>
          <span className="cntrt-m-icon cntrt--close">
            <svg className="cntrt-svg--menu-close" xmlns="http://www.w3.org/2000/svg" width="50" height="1" viewBox="0 0 50 1">
              <path d="M0 0h50v1H0z"></path>
            </svg>
          </span>
        </div>

      <FullscreenArea isOpen={isFullscreenOpen} onClose={closeFullscreen} />

      {isFullscreenOpen && <FullscreenArea />}
      </div>

      
      
    </header>
    <Mheader />
    </>
  );
};

export default Header;



