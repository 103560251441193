import React from 'react';
import ContactForm from './component/ContactForm';

const Contact = () => {
  return (
    <div id="cntrt-page-outer">
      <div id="cntrt-page-inner" className="cntrt-content-full-width">
        <main id="cntrt-page-content" className="cntrt-grid cntrt-layout--template" role="main">
          <div className="cntrt-grid-inner clear">
            <div className="cntrt-grid-item cntrt-page-content-section cntrt-col--12">
              <div className="level level-3070">
                {/* Contact Us Section */}
                <section className="ser-second level-section level-section-full_width">
                  <div className="level-container mob-pdt-50">
                    <div className="level-column level-col-50">
                      <div className="level-widget-wrap">
                        {/* Section Title */}
                        <div className="cntrt-section-title cntrt-alignment--left cont-head">
                          <h1 className="cntrt-m-title">Contact Us</h1>
                          <p className="cntrt-m-text">
                          If you have any questions or queries related to our services, please feel free to contact us. Fill <br />out the form below, and we will get back to you as soon as possible.
                          </p>
                        </div>
                        {/* Contact Form */}
                        <div className="cntrt-contact-form-7 mob-mrb-50">
                          <ContactForm/>
                        </div>
                      </div>
                    </div>
                    <div className="level-column level-col-50 flex-space-evenly cont-head" >
                      <div className="flex-main">
                        {/* Direct Contact */}
                        <div className="cntrt-section-title cntrt-alignment--left mrr-60 ">
                          <div>
                          <h6 className="cntrt-m-title mrb-20">Direct Contact</h6>
                        </div>
                        <p>
                          <a href="https://www.google.com/maps/place/123+Terra+Street,+Copenhagen"
                            target="_blank"
                            rel="noopener noreferrer">
                            2125 Chamomile ct <br />
                            San Ramon, Ca 94582
                          </a>
                        </p>
                        <p><a href="tel:0381234567">818.297.2920</a></p>
                        <p><a href="mailto:levelcontractor1@gmail.com">levelcontractor1@gmail.com</a></p>
                          </div>
                        
                        <div className='contact-hour'>
                        <div className="cntrt-section-title cntrt-alignment--left ">
                          <h6 className="cntrt-m-title ">Business Hours</h6>
                        </div>
                        <p>Monday to Friday</p>
                        <p>9:00 AM - 5:00 PM<br />Saturday - Sunday Closed</p>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </section>
                {/* Office Locations Section */}
                <section className=" level-section level-section-full_width">
                <div style={{ width: '100%', height: '450px' }}>
      <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.983810362701!2d-121.91434720000001!3d37.76697779999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fedf094443745%3A0x3b540689fc3c079a!2s2125%20Chamomile%20Ct%2C%20San%20Ramon%2C%20CA%2094582%2C%20USA!5e0!3m2!1sen!2sin!4v1724693732533!5m2!1sen!2sin"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Contact;
