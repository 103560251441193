import React from 'react'
import { Link } from 'react-router-dom'

export const Error = () => {
  return (
    <>
    <div style={{ textAlign: 'center', marginTop: '50px', marginBottom: '50px' }}>
      <h1>404 - Page Not Found</h1>
      <p>Oops! The page you are looking for does not exist.</p>
      <Link to="/">Go Back Home</Link>
    </div>
    </>
  )
}
