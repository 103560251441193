// src/BannerSlider.js
import React, { useState, useEffect } from 'react';
import './BannerSlider.css'; // Make sure this CSS file exists and is correctly referenced


const images = [
  './images/Home/Banner-2.webp',
  './images/Home/Banner-1.webp',
  './images/Home/Banner-3.webp',
  './images/Home/Banner-4.webp',
];

const BannerSlider = () => {
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [isAnimating, setIsAnimating] = useState(false);

  let debounceTimer;
  const handleMouseEnter = (index) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setIsAnimating(true);
      setCurrentImage(images[index]);
    }, 100);
  };

  useEffect(() => {
    if (isAnimating) {
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 1000); // Match this duration to your animation duration
      return () => clearTimeout(timer);
    }
  }, [isAnimating]);

  return (
    
      <div className="banner-container">
        <div className="header-list">
          {['Kitchen Remodeling', 'Home Remodeling', 'Bathroom Remodeling', 'Landscaping'].map((header, index) => (
            <div
              key={index}
              className="header-item"
              onMouseEnter={() => handleMouseEnter(index)}
              onClick={() => handleMouseEnter(index)}>
              {header}
            </div>
          ))}
        </div>
        <div className={`banner-image ${isAnimating ? 'animate' : ''}`}>
        <img src={currentImage} alt="Banner" />
        </div>
      </div>
  );
};

export default BannerSlider;
