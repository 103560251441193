import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonials = () => {
    const testimonials = [
      {
        name: 'Sarah M.',
        title: 'San Ramon, CA',
        text: 'Level Contractor transformed our outdated kitchen into a modern masterpiece. Their attention to detail and commitment to quality was evident from start to finish.',
        image: './images/Testimonial-1.jpg',
        signature: './images/Sarah-M.png',
      },
      {
        name: 'John D.',
        title: 'Pleasanton, CA',
        text: 'We hired Level Contractor for a complete home remodel, and the experience was seamless. The team was professional, reliable, and delivered everything on time.',
        image: './images/Testimonial-2.jpg',
        signature: './images/John-D.png',
      },
      {
        name: 'Emily R.',
        title: 'Danville, CA',
        text: 'From the initial consultation to the final walkthrough, Level Contractor exceeded our expectations. They converted our garage into a beautiful living space.',
        image: './images/Testimonial-3.jpg',
        signature: './images/Emily-R.png',
      },
      // Add more testimonials as needed
    ];
  
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
  
    return (
      <div className="testimonials-container">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div className="testimonial" key={index}>
              <div className="testimonial-image">
                <img src={testimonial.image} alt={testimonial.name} />
              </div>
              <div className="testimonial-content">
                <h3>{testimonial.name}</h3>
                <p className="testimonial-title">{testimonial.title}</p>
                <p className="testimonial-text">{testimonial.text}</p>
                <img src={testimonial.signature} alt="Signature" className="testimonial-signature" />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  };
  
  export default Testimonials;
