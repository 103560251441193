import React, { useEffect, useRef } from "react";
import BannerSlider from "./component/Banner/BannerSlider";
import Testimonials from "./component/TestimonialsSlider/Testimonials";
import HomeService from "./component/HomeService";
import ServiceList from "./component/ServiceList";
import HomeWork from "./component/HomeWork";
import HomeContact from "./component/HomeContact";

const Home = () => {
  const sectionsRef = useRef([]);
  
  // Apply transition effect to the body element
  document.body.style.transition = 'background-color .6s ease-out';

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.3) {
          const newColor = entry.target.getAttribute('data-color');
          document.body.style.backgroundColor = newColor;
        }
      });
    }, {
      threshold: 0.5,
    });

    sectionsRef.current.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div id="cntrt-page-outer">
        <div id="cntrt-page-inner" className="cntrt-content-full-width">
          <main
            id="cntrt-page-content"
            className="cntrt-grid cntrt-layout--template"
            role="main"
          >
            <div className="cntrt-grid-inner clear">
              <div className="cntrt-grid-item cntrt-page-content-section cntrt-col--12">
                <div className="level level-1303">

                  <section
                    data-color="#a9b1ab" 
                    className="level-section level-top-section level-element level-element-fbc184f level-section-full_width level-section-height-default level-section-height-default"
                    ref={el => sectionsRef.current[0] = el}
                  >
                    <BannerSlider />
                  </section>

                  <section
                    data-color="#D9D0D0" 
                    className="level-section level-top-section level-element level-element-309e9e6 level-section-full_width level-section-height-default level-section-height-default cntrt-level-content-no cntrt-level-column-no"
                    ref={el => sectionsRef.current[1] = el}
                  >
                    <HomeService />
                  </section>

                  <section
                    data-color="#d9d0d0" 
                    className="level-section level-top-section level-element level-element-5192a63 level-section-full_width level-section-height-default level-section-height-default cntrt-level-content-no cntrt-level-column-no mid-main-sec"
                    ref={el => sectionsRef.current[2] = el}
                  >
                    <ServiceList />
                  </section>

                  <section
                    data-color="#D9D0D0" 
                    className="level-section level-top-section level-element level-element-497a2c7 level-section-full_width level-section-height-default level-section-height-default cntrt-level-content-no cntrt-level-column-no"
                    ref={el => sectionsRef.current[3] = el}
                  >
                    <HomeWork />
                  </section>

                  <section
                    data-color="#a9b1ab" 
                    className="level-section level-top-section level-element level-section-full_width level-section-height-default level-section-height-default cntrt-level-content-no cntrt-level-column-no"
                    ref={el => sectionsRef.current[4] = el}
                  >
                    <Testimonials />
                  </section>

                  <section
                    data-color="#F4F3EF" 
                    className="level-section level-top-section level-element level-element-4e75dd2 level-section-full_width level-section-height-default level-section-height-default cntrt-level-content-no cntrt-level-column-no"
                    ref={el => sectionsRef.current[5] = el}
                  >
                    <HomeContact />
                  </section>

                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Home;
