// import React, { useState, useEffect } from 'react';
import './FullscreenArea.css';
import { Link } from 'react-router-dom';

const FullscreenArea = ({ isOpen, onClose }) => {
  // const [isTouchDevice, setIsTouchDevice] = useState(false);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const checkTouchDevice = () => {
  //     setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
  //   };
  //   checkTouchDevice();
  //   window.addEventListener('resize', checkTouchDevice);
  //   return () => window.removeEventListener('resize', checkTouchDevice);
  // }, []);

  // const handleLinkClick = (path) => {
  //   onClose(); // Close the fullscreen area
  //   navigate(path); // Navigate to the desired page
  // };

  if (!isOpen) return null;

  return (
    <div className="fullscreen-area">
      <div className="fullscreen-left">
        <Link to="/" onClick={onClose}>
        <img itemProp="image" className='pop-logo ' src="https://level-contractor.thepreview.pro/images/logo.png" alt="Level Contractor" />
                      </Link>
        <ul className="fullscreen-menu">
        <li><Link to="/" onClick={onClose}>Home</Link></li>
          <li><Link to="/services" onClick={onClose}>Services</Link></li>
          <li><Link to="/blog" onClick={onClose}>Blog</Link></li>
          <li><Link to="/work" onClick={onClose}>Our Work</Link></li>
          <li><Link to="/contact" onClick={onClose}>Contact</Link></li>
          
        </ul>
      </div>
      <div className="fullscreen-right">
        <img
          src="https://level-contractor.thepreview.pro/images/Navigation-Bar.webp"
          alt="Architect"
          className="fullscreen-image"
        />
        <footer className="fullscreen-footer">
          <span>2125 Chamomile ct San Ramon, Ca 94582</span>
          <span>levelcontractor1@gmail.com</span>
        </footer>
      </div>
      <button className="close-button" onClick={onClose} aria-label="Close Fullscreen Area">
        X
      </button>
    </div>
  );
};

export default FullscreenArea;