import React from 'react'
import { Link } from 'react-router-dom' 

const HomeService = () => {
  return (
    <div>
        
                    <div className="level-container level-column-gap-default">
                      <div
                        className="level-column level-col-50 level-top-column level-element level-element-8771755"
                        data-id={8771755}
                        data-element_type="column"
                      >
                        <div className="level-widget-wrap level-element-populated">
                          <div
                            className="level-element level-element-73bb9fc level-widget-tablet__width-auto level-widget level-widget-level_core_portfolio_list"
                            data-id="73bb9fc"
                            data-element_type="widget"
                            data-widget_type="level_core_portfolio_list.default"
                          >
                            <div className="level-widget-container">
                              <div
                                className="cntrt-shortcode cntrt-m  cntrt-portfolio-list cntrt-item-layout--info-below  cntrt-grid cntrt-layout--columns  cntrt-gutter--tiny cntrt-col-num--2 cntrt-item-layout--info-below cntrt--no-bottom-space cntrt-pagination--off cntrt-responsive--custom cntrt-col-num--1440--2 cntrt-col-num--1366--2 cntrt-col-num--1024--2 cntrt-col-num--768--2 cntrt-col-num--680--1 cntrt-col-num--480--1 cntrt-hover-animation--move-text"
                                data-options='{"plugin":"level_core","module":"post-types\/portfolio\/shortcodes","shortcode":"portfolio-list","post_type":"portfolio-item","next_page":"2","max_pages_num":3,"behavior":"columns","images_proportion":"full","columns":"2","columns_responsive":"custom","columns_1440":"2","columns_1366":"2","columns_1024":"2","columns_768":"2","columns_680":"1","columns_480":"1","space":"tiny","posts_per_page":"2","orderby":"date","order":"ASC","additional_params":"tax","tax":"portfolio-category","tax_slug":"details","layout":"info-below","hover_animation_info-below":"move-text","hover_animation_info-follow":"follow","hover_animation_info-on-hover":"fade-in","title_tag":"h5","enable_category":"yes","zigzag":"no","custom_margin":"no","enable_appear":"no","pagination_type":"no-pagination","object_class_name":"HiroshiCore_Portfolio_List_Shortcode","taxonomy_filter":"portfolio-category","additional_query_args":{"tax_query":[{"taxonomy":"portfolio-category","field":"slug","terms":"details"}]},"space_value":4}'
                              >
                                <div className="cntrt-grid-inner clear">
                                  <article className="cntrt-e cntrt-grid-item cntrt-item--full  post-4992 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-details portfolio-tag-residential portfolio-tag-small">
                                    <div className="cntrt-e-inner">
                                      <div className="cntrt-e-image">
                                        <div className="cntrt-e-media-image">
                                          <Link to="/services/home-additions"
                                            target="_self" >
                                            <img
                                              loading="lazy"
                                              decoding="async"
                                              width={1100}
                                              height={1545}
                                              src="images/Home/Home-Additions.webp"
                                              className="attachment-full size-full wp-post-image"
                                              alt="d"
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="cntrt-e-content">
                                        <div className="cntrt-e-text">
                                          <h5
                                            itemProp="name"
                                            className="cntrt-e-title entry-title">
                                            <Link className="cntrt-e-title-link"
                                              to="/services/home-additions"
                                              target="_self"
                                            >
                                              Home Additions{" "}
                                            </Link>
                                          </h5>
                                        </div>
                                        <div className="cntrt-e-top-holder">
                                          <div className="cntrt-e-info">
                                            <Link to="/services/home-additions" rel="tag">
                                              Details
                                            </Link>
                                            <div className="cntrt-info-separator-end"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </article>
                                  <article className="cntrt-e cntrt-grid-item cntrt-item--full  post-4993 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-details portfolio-tag-small">
                                    <div className="cntrt-e-inner">
                                      <div className="cntrt-e-image">
                                        <div className="cntrt-e-media-image">
                                          <Link to="/services/garage-conversions" target="_self" >
                                            <img
                                              loading="lazy"
                                              decoding="async"
                                              width={1100}
                                              height={1545}
                                              src="images/Home/Garage-Conversion.webp"
                                              className="attachment-full size-full wp-post-image"
                                              alt="d"
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="cntrt-e-content">
                                        <div className="cntrt-e-text">
                                          <h5
                                            itemProp="name"
                                            className="cntrt-e-title entry-title"
                                          >
                                            <Link className="cntrt-e-title-link"
                                              to="/services/garage-conversionss"
                                              target="_self"
                                            >
                                              Garage Conversion{" "}
                                            </Link>
                                          </h5>
                                        </div>
                                        <div className="cntrt-e-top-holder">
                                          <div className="cntrt-e-info">
                                            <Link to="/services/garage-conversions" rel="tag">
                                              Details
                                            </Link>
                                            <div className="cntrt-info-separator-end"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="level-column level-col-50 level-top-column level-element level-element-8771755"
                        data-id={8771755}
                        data-element_type="column"
                      >
                        <div className="level-widget-wrap level-element-populated">
                          <div
                            className="level-element level-element-73bb9fc level-widget-tablet__width-auto level-widget level-widget-level_core_portfolio_list"
                            data-id="73bb9fc"
                            data-element_type="widget"
                            data-widget_type="level_core_portfolio_list.default"
                          >
                            <div className="level-widget-container">
                              <div
                                className="cntrt-shortcode cntrt-m  cntrt-portfolio-list cntrt-item-layout--info-below  cntrt-grid cntrt-layout--columns  cntrt-gutter--tiny cntrt-col-num--2 cntrt-item-layout--info-below cntrt--no-bottom-space cntrt-pagination--off cntrt-responsive--custom cntrt-col-num--1440--2 cntrt-col-num--1366--2 cntrt-col-num--1024--2 cntrt-col-num--768--2 cntrt-col-num--680--1 cntrt-col-num--480--1 cntrt-hover-animation--move-text"
                                data-options='{"plugin":"level_core","module":"post-types\/portfolio\/shortcodes","shortcode":"portfolio-list","post_type":"portfolio-item","next_page":"2","max_pages_num":3,"behavior":"columns","images_proportion":"full","columns":"2","columns_responsive":"custom","columns_1440":"2","columns_1366":"2","columns_1024":"2","columns_768":"2","columns_680":"1","columns_480":"1","space":"tiny","posts_per_page":"2","orderby":"date","order":"ASC","additional_params":"tax","tax":"portfolio-category","tax_slug":"details","layout":"info-below","hover_animation_info-below":"move-text","hover_animation_info-follow":"follow","hover_animation_info-on-hover":"fade-in","title_tag":"h5","enable_category":"yes","zigzag":"no","custom_margin":"no","enable_appear":"no","pagination_type":"no-pagination","object_class_name":"HiroshiCore_Portfolio_List_Shortcode","taxonomy_filter":"portfolio-category","additional_query_args":{"tax_query":[{"taxonomy":"portfolio-category","field":"slug","terms":"details"}]},"space_value":4}'
                              >
                                <div className="cntrt-grid-inner clear">
                                  <article className="cntrt-e cntrt-grid-item cntrt-item--full  post-4992 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-details portfolio-tag-residential portfolio-tag-small">
                                    <div className="cntrt-e-inner">
                                      <div className="cntrt-e-image">
                                        <div className="cntrt-e-media-image">
                                          <Link to="/services/roofing"
                                            target="_self"
                                          >
                                            <img
                                              loading="lazy"
                                              decoding="async"
                                              width={1100}
                                              height={1545}
                                              src="images/Home/Roofing.webp"
                                              className="attachment-full size-full wp-post-image"
                                              alt="d"
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="cntrt-e-content">
                                        <div className="cntrt-e-text">
                                          <h5
                                            itemProp="name"
                                            className="cntrt-e-title entry-title"
                                          >
                                            <Link  to="/services/roofing"
                                              className="cntrt-e-title-link"
                                              target="_self"
                                            >
                                              Roofing{" "}
                                            </Link>
                                          </h5>
                                        </div>
                                        <div className="cntrt-e-top-holder">
                                          <div className="cntrt-e-info">
                                            <Link to="/services/roofing" rel="tag">
                                              Details
                                            </Link>
                                            <div className="cntrt-info-separator-end"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </article>
                                  <article className="cntrt-e cntrt-grid-item cntrt-item--full  post-4993 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-details portfolio-tag-small">
                                    <div className="cntrt-e-inner">
                                      <div className="cntrt-e-image">
                                        <div className="cntrt-e-media-image">
                                          <Link to="/services/adu"
                                            target="_self"
                                          >
                                            <img
                                              loading="lazy"
                                              decoding="async"
                                              width={1100}
                                              height={1545}
                                              src="images/Home/Accessory-Dwelling-Unit.webp"
                                              className="attachment-full size-full wp-post-image"
                                              alt="d"
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="cntrt-e-content">
                                        <div className="cntrt-e-text">
                                          <h5
                                            itemProp="name"
                                            className="cntrt-e-title entry-title"
                                          >
                                            <Link to="/services/adu"
                                              className="cntrt-e-title-link"
                                              target="_self"
                                            >
                                              Accessory Dwelling Unit{" "}
                                            </Link>
                                          </h5>
                                        </div>
                                        <div className="cntrt-e-top-holder">
                                          <div className="cntrt-e-info">
                                            <Link to="/services/adu" rel="tag">
                                              Details
                                            </Link>
                                            <div className="cntrt-info-separator-end"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
    </div>
  )
}

export default HomeService