import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';

const images = [
  { src: './images/Work/Home-Remodeling1.webp', thumb: './images/Work/Home-Remodeling1.webp', category: 'Home Remodeling', title: 'Home Remodeling' },
  { src: './images/Work/Kitchen-Remodeling1.webp', thumb: './images/Work/Kitchen-Remodeling1.webp', category: 'Kitchen Remodeling', title: 'Kitchen Remodeling' },
  { src: './images/Work/Driveways1.webp', thumb: './images/Work/Driveways1.webp', category: 'Driveways', title: 'Driveways' },
  { src: './images/Work/ADU-1.webp', thumb: './images/Work/ADU-1.webp', category: 'ADU', title: 'ADU' },
  { src: './images/Work/Landscaping1.webp', thumb: './images/Work/Landscaping1.webp', category: 'Landscaping', title: 'Landscaping' },
  { src: './images/Work/Bathroom-Remodeling1.webp', thumb: './images/Work/Bathroom-Remodeling1.webp', category: 'Bathroom Remodeling', title: 'Bathroom Remodeling' },
  { src: './images/Work/Garge-Conversations1.webp', thumb: './images/Work/Garge-Conversations1.webp', category: 'Garge Conversations', title: 'Garge Conversations' },
  { src: './images/Work/Window-Installation1.webp', thumb: './images/Work/Window-Installation1.webp', category: 'Window Installation', title: 'Window Installation' },
  { src: './images/Work/Interior-Painting1.webp', thumb: './images/Work/Interior-Painting1.webp', category: 'Interior Painting', title: 'Interior Painting' },
  { src: './images/Work/Kitchen-Remodeling2.webp', thumb: './images/Work/Kitchen-Remodeling2.webp', category: 'Kitchen Remodeling', title: 'Kitchen Remodeling' },
  { src: './images/Work/ADU-2.webp', thumb: './images/Work/ADU-2.webp', category: 'ADU', title: 'ADU' },
  { src: './images/Work/Home-Remodeling2.webp', thumb: './images/Work/Home-Remodeling2.webp', category: 'Home Remodeling', title: 'Home Remodeling' },
  { src: './images/Work/Garge-Conversations2.webp', thumb: './images/Work/Garge-Conversations2.webp', category: 'Garge Conversations', title: 'Garge Conversations' },
  { src: './images/Work/Bathroom-Remodeling2.webp', thumb: './images/Work/Bathroom-Remodeling2.webp', category: 'Bathroom Remodeling', title: 'Bathroom Remodeling' },
  { src: './images/Work/Landscaping2.webp', thumb: './images/Work/Landscaping2.webp', category: 'Landscaping', title: 'Landscaping' },
  { src: './images/Work/Roofing1.webp', thumb: './images/Work/Roofing1.webp', category: 'Roofing', title: 'Roofing' },
  { src: './images/Work/Home-Additions-1.webp', thumb: './images/Work/Home-Additions-1.webp', category: 'Home Additions', title: 'Home Additions' },
  { src: './images/Work/Window-Installation2.webp', thumb: './images/Work/Window-Installation2.webp', category: 'Window Installation', title: 'Window Installation' },
  { src: './images/Work/Door-Installation1.webp', thumb: './images/Work/Door-Installation1.webp', category: 'Door Installation', title: 'Door Installation' },
  { src: './images/Work/Exterior-Painting1.webp', thumb: './images/Work/Exterior-Painting1.webp', category: 'Exterior Painting', title: 'Exterior Painting' },
  { src: './images/Work/Interior-Painting2.webp', thumb: './images/Work/Interior-Painting2.webp', category: 'Interior Painting', title: 'Interior Painting' },
  { src: './images/Work/Home-Additions-2.webp', thumb: './images/Work/Home-Additions-2.webp', category: 'Home Additions', title: 'Home Additions' },
  { src: './images/Work/Concrete-work-1.webp', thumb: './images/Work/Concrete-work-1.webp', category: 'Concrete Work', title: 'Concrete Work' },
  { src: './images/Work/Door-Installation2.webp', thumb: './images/Work/Door-Installation2.webp', category: 'Door Installation', title: 'Door Installation' },
  { src: './images/Work/Exterior-Painting2.webp', thumb: './images/Work/Exterior-Painting2.webp', category: 'Exterior Painting', title: 'Exterior Painting' },
  { src: './images/Work/Roofing2.webp', thumb: './images/Work/Roofing2.webp', category: 'Roofing', title: 'Roofing' },
  { src: './images/Work/Driveways2.webp', thumb: './images/Work/Driveways2.webp', category: 'Driveways', title: 'Driveways' },
  { src: './images/Work/Concrete-work-2.webp', thumb: './images/Work/Concrete-work-2.webp', category: 'Concrete Work', title: 'Concrete Work' },
  { src: './images/Work/Kitchen-Remodeling-3.webp', thumb: './images/Work/Kitchen-Remodeling-3.webp', category: 'Kitchen Remodeling', title: 'Kitchen Remodeling' },
  { src: './images/Work/Bathroom-Remodeling-3.webp', thumb: './images/Work/Bathroom-Remodeling-3.webp', category: 'Bathroom Remodeling', title: 'Bathroom Remodeling' },
];

const categories = ['All', 'Home Remodeling', 'Kitchen Remodeling', 'Bathroom Remodeling', 'ADU', 'Home Additions', 'Garge Conversations', 'Roofing', 'Landscaping', 'Window Installation', 'Door Installation', 'Driveways', 'Concrete Work', 'Exterior Painting', 'Interior Painting'];

const Work = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [filteredImages, setFilteredImages] = useState(images);

  useEffect(() => {
    // Initialize Magnific Popup
    $('.gallery').magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true
      },
      mainClass: 'mfp-fade',
      removalDelay: 300,
    });
  }, []);

  useEffect(() => {
    if (selectedCategory === 'All') {
      setFilteredImages(images);
    } else {
      setFilteredImages(images.filter(image => image.category === selectedCategory));
    }
  }, [selectedCategory]);

  return (
    <div id="cntrt-page-outer">
    <div id="cntrt-page-inner" className="cntrt-content-full-width">
      <main id="cntrt-page-content" className="cntrt-grid cntrt-layout--template" role="main">
        <div className="cntrt-grid-inner clear">
          <div className="cntrt-grid-item cntrt-page-content-section cntrt-col--12" >
            <div className="level level-3070">
        <div className='ser-second'>
    <h2 className='work-heading'>Our Work Portfolio</h2>
    <div className="category-buttons">
      {categories.map(category => (
        <button
          key={category}
          onClick={() => setSelectedCategory(category)}
          className={selectedCategory === category ? 'active' : ''}
        >
          {category}
        </button>
        
      ))}
      
    </div>
    <div className="gallery">
      {filteredImages.map((image, index) => (
        <a key={index} href={image.src} title={image.title}>
          <img src={image.thumb} alt={image.title} />
        </a>
      ))}
    </div>
  </div>
        </div>
      </div>
    </div>
  </main>
    </div>
</div>
  );
};

export default Work;
