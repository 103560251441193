import React from 'react';
import './Blogs.css'
import { Link } from 'react-router-dom';


const Blog = () => {
  return (
    <>
    {/* First Section */}
    <div className="ser-first  level-top-section level-element level-section-full_width level-section-height-default">
                <div className="level-container level-column-gap-default">
                  <div className="level-column level-col-100">
                    <div className="level-widget-wrap " style={{textAlign:"center"}}>
                      <h1>Our Blog</h1>
                    </div>
                  </div>
                </div>
              </div>
    <div className="blog-section">
      <Link to="/6-common-bathroom-remodel-mistakes-and-how-to-avoid-them" className="blog-image-container">
        <img src="images/Blogs/6-Common-Bathroom-Remodel-Mistakes-and-How-to-Avoid-Them.webp" alt="Living Room Trends" className="blog-image" />
        <div className="image-index">1/3</div>
      </Link>
      <div className="blog-content">
        <Link to="/6-common-bathroom-remodel-mistakes-and-how-to-avoid-them" className="blog-title-link">
          <h2 className="blog-title">6 Common Bathroom Remodel Mistakes and How to Avoid Them</h2>
        </Link>
        <div className="blog-meta">
          <span className="blog-category">Bathroom Remodel</span> | <span className="blog-date">Sep 14, 2024</span>
        </div>
      </div>
    </div>
    <div className="blog-section">
      <Link to="/top-trends-in-home-remodeling-transforming-your-space-with-style" className="blog-image-container">
        <img src="images/Blogs/Top-Trends-In-Home-Remodeling-Transforming-Your-Space-With-Style.webp" alt="Living Room Trends" className="blog-image" />
        <div className="image-index">1/3</div>
      </Link>
      <div className="blog-content">
       
        <Link to="/top-trends-in-home-remodeling-transforming-your-space-with-style" className="blog-title-link">
          <h2 className="blog-title">Top Trends In Home Remodeling: Transforming Your Space With Style</h2>
        </Link>
        <div className="blog-meta">
          <span className="blog-category">Home Remodeling</span> | <span className="blog-date">Sep 14, 2024</span>
        </div>
      </div>
    </div>
    <div className="blog-section">
      <Link to="/how-to-estimate-new-home-construction-cost-a-complete-guide" className="blog-image-container">
        <img src="images/Blogs/How-to-estimate-new-home-construction-cost-A-complete-guide.webp" alt="Living Room Trends" className="blog-image" />
        <div className="image-index">1/3</div>
      </Link>
      <div className="blog-content">
      <Link to="/how-to-estimate-new-home-construction-cost-a-complete-guide" className="blog-title-link">
          <h2 className="blog-title">How to estimate new home construction cost? A complete guide</h2>
        </Link>
        <div className="blog-meta">
          <span className="blog-category">Landscape Architecture</span> | <span className="blog-date">Sep 14, 2024</span>
        </div>
      </div>
    </div>
    </>
  );
};

export default Blog;
