import React from 'react'
import { Link } from 'react-router-dom'

const ServiceList = () => {
  return (
    <div>
        
                    <div className="hom-mid-banner">
                      <div >
                          <div className="mid-banner-head">
                            <h3>At Level Contractor</h3>
                          <h5>We specialize in providing exceptional home construction and remodeling<br /> services in San Ramon, California.</h5>
                          
                          <div className="mid-banner-service">
                            <ul>
                              <Link to="/services"><li>Home Remodeling</li></Link>
                              <Link to="/services/kitchen-remodeling"><li>Kitchen Remodeling</li></Link>
                              <Link to="/services/bathroom-remodeling"><li>Bathroom Remodeling</li></Link>
                              <Link to="/services/home-additions"><li>Home Additions</li></Link>
                              <Link to="/services/adu"><li>ADU</li></Link>
                              <Link to="/services/garage-conversions"><li>Garge Conversations</li></Link>
                              <Link to="/services/exterior-painting"><li>Exterior Painting</li></Link>

                            </ul>
                            <ul>
                            <Link to="/services/roofing"><li>Roofing</li></Link>
                              <Link to="/services/landscaping"><li>Landscaping</li></Link>
                              <Link to="/services/window-installation"><li>Window Installation</li></Link>
                              <Link to="/services/door-installation"><li>Door Installation</li></Link>
                              <Link to="/services/driveways"><li>Driveways</li></Link>
                              <Link to="/services/concrete-work"><li>Concrete Work</li></Link>
                              <Link to="/services/interior-painting"><li>Interior Painting</li></Link>

                            </ul>
                          </div>
                          
                          </div>
                          <div className='category-buttons ser-cont mid-btn'><Link to="/contact"><button >Contact Us</button></Link></div>
                          
                      </div>
                    </div>
    </div>
  )
}

export default ServiceList